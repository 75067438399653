<template>
    <div class="group_item">
        <label class="input_label">
            Coach Bio
            <div class="flex-center">
                <button type="button" class="reset-default" @click="resetToDefaultBio()">Reset to default</button>
                <div class="dropdown px-1" @click="msgOption = !msgOption">
                    <i class="fas fa-ellipsis-h"></i>
                    <div class="drp_wrapper" v-if="msgOption" :class="{ active: msgOption }">
                        <ul>
                            <li @click="templateLibrary = !templateLibrary" :class="{ active : templateLibrary }">Import Template</li>
                        </ul>
                    </div>
                </div>
            </div>
        </label>
        <div class="editorWpr" :class="{ 'has-error': errors[`coach_bio_${fieldKey}`] }">
            <Field autocomplete="off" :name="`coach_bio_${fieldKey}`" v-model="form.coach_bio" :class="{ 'has-error': errors[`coach_bio_${fieldKey}`] }" :rules="{ required_editor: validate }" label="coach bio">
                <redactor v-model="form.coach_bio" :default-value="editorDefaultValue" :reset-watcher="resetWatcher" :name="`coach_bio_${fieldKey}`" />
            </Field>
        </div>
        <ErrorMessage :name="`coach_bio_${fieldKey}`" class="text-danger" />
        <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="content" />
    </div>
</template>

<script>
    import TemplateLibrary from '@/components/TemplateLibrary'
    import Redactor from '@/components/Redactor'

    import { Field, ErrorMessage } from 'vee-validate'
    import { uuid } from 'vue-uuid'
    import { mapState, mapActions, mapMutations } from 'vuex'

    export default {
        name: 'Coach Bio Component',

        data () {
            return {
                form: {
                    coach_bio: '',
                },
                msgOption: false,
                templateLibrary: false,
                resetWatcher: 0,
                fieldKey: new Date().getUTCMilliseconds(),
                editorDefaultValue: '',
            };
        },

        props: {
            modelValue: String,
            errors: [Object, Array],
            defaultValue: {
                type: String,
                default: null,
            },
            resetToDefaultWatcher: Number,
            validate: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue'],

        components: {
            TemplateLibrary,
            Redactor,
            Field,
            ErrorMessage,
        },

        watch: {
            form: {
                handler (form) {
                    const vm = this;
                    vm.$emit('update:modelValue', form.coach_bio);
                },
                deep: true
            },

            modelValue: {
                handler (bio) {
                    const vm = this;

                    vm.form.coach_name = bio;
                    // vm.editorDefaultValue = bio;
                    // vm.resetWatcher += 1;
                },
                deep: true
            },

            resetToDefaultWatcher (val) {
                const vm = this;

                vm.form.coach_bio = vm.modelValue;
                vm.editorDefaultValue = vm.modelValue;
                vm.resetWatcher += 1;
            },
        },

        mounted () {
            const vm = this;

            vm.form.coach_name = vm.modelValue;
            vm.editorDefaultValue = vm.modelValue;
            vm.resetWatcher += 1;
        },

        methods: {
            handleTemplateImport (template) {
                const vm = this;

                vm.form.coach_bio = template.content;
                vm.editorDefaultValue = template.content;

                vm.resetWatcher += 1;
            },

            resetToDefaultBio () {
                const vm = this;

                vm.form.coach_bio = vm.defaultValue;
                vm.editorDefaultValue = vm.defaultValue;
                vm.resetWatcher += 1;
            },
        },
    };
</script>

<style scoped>
    .input_label {
        position: relative;
        z-index: 3;
    }
</style>
