<template>
    <div v-if="modelValue" class="edit_section mt-4"  :id="`template_library-${templateUuid}`">
        <div class="section_title mb-1">Import template</div>
        <div class="library_wpr">
            <div class="card_item" v-for="(ty, t) of type.split(',')" :key="t">
                <img :src="smsImage" v-if="ty == 'sms'">
                <img :src="smsImage" v-if="ty == 'email'">
                <img :src="smsImage" v-if="ty == 'content'">
                <img :src="smsImage" v-if="ty == 'voice'">
                <span class="uppercase">{{ ty }}</span>
            </div>
            <div class="library_field">
                <div class="form_grp w-100 m-0">
                    <div class="group_item w-100 p-0">
                        <label class="input_label">Category</label>
                        <div class="field_wpr w-100">
                            <multiselect
                                v-model="category"
                                :options="categories"
                                value-prop="type"
                                label="type"
                                :searchable="true"
                                placeholder="Select category"
                            ></multiselect>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form_grp w-100" v-show="category">
            <div class="group_item w-100" v-if="templates.length && !templateAjaxLoader">
                <div class="field_wpr w-100">
                    <multiselect
                        v-model="template"
                        :options="templates"
                        value-prop="id"
                        label="title"
                        :searchable="true"
                        placeholder="Select template"
                    ></multiselect>
                </div>
            </div>
            <div class="text-center w-100" v-else>
                <p v-if="templateAjaxLoader">Loading Templates, please wait...</p>
                <p v-else>No template found for this category</p>
            </div>
        </div>
        <div class="action_wpr m-0 p-0">
            <button class="btn cancel_btn" @click="closeLibrary">Cancel</button>
            <button class="btn save_btn" :disabled="!template" @click="handleImport">Import</button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { uuid } from 'vue-uuid'

    import smsImage from '@/assets/images/sms.svg'
    import emailImage from '@/assets/images/sms.svg'
    import contentImage from '@/assets/images/sms.svg'
    import voiceImage from '@/assets/images/sms.svg'
    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'Template Library',

        data () {
            return  {
                template: '',
                category: '',
                smsImage,
                emailImage,
                contentImage,
                voiceImage,
                templateUuid: 'template-'+uuid.v4(),
            };
        },

        props: {
            modelValue: Boolean,
            type: {
                type: String,
                default: 'email',
            },
            onImport: Function,
        },

        emits: ['update:modelValue'],

        components: {
            Multiselect,
        },

        watch: {
            category (category) {
                const vm = this;

                vm.template = '';
                vm.getTemplates({ category, type: vm.type});
            },

            modelValue (val) {
                if (val) {
                    const vm = this;
                    const startTime = Date.now();

                    const checkElement = setInterval(function () {
                        const el = document.getElementById(`template_library-${vm.templateUuid}`);

                        if (el) {
                            el.scrollIntoView(true);
                            clearInterval(checkElement);
                        } else if (Date.now() - startTime > 2000) {
                            clearInterval(checkElement);
                        }
                    }, 10);
                }
            }
        },

        computed: mapState({
            categories: state => state.templateModule.categories,
            templates: state => state.templateModule.templates,
            categoryLoadedAt: state => state.templateModule.categoryLoadedAt,
            templateAjaxLoader: state => state.templateModule.templateAjaxLoader,
        }),

        mounted () {
            const vm = this;
            let refresh = false;

            if (vm.categoryLoadedAt && (Date.now() - vm.categoryLoadedAt) > 1800000) {
                refresh = true;
            }

            if (vm.categories.length == 0 || refresh) {
                vm.getCategories();
            }
        },

        methods: {
            ...mapActions({
                getCategories: 'templateModule/getCategories',
                getTemplates: 'templateModule/getTemplates',
            }),

            closeLibrary () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleImport () {
                const vm = this;
                const template = vm.templates.filter(template => template.id == vm.template)[0];

                vm.onImport(template);
                vm.closeLibrary();
            },
        },
    };
</script>
